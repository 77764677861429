import { useState } from "react";
import { usePincode } from "../usePincode";
import { getFutureDate } from "@tm/utility";

export enum AREA_TYPE {
  SERVICABLE = "SEVICABLE",
  NON_SERVICABLE = "NON_SERVICABLE",
}

const isPincodeInList = async ({ pincodes, inputPincode }: any) => {
  return new Promise((resolve) => {
    if (inputPincode) {
      inputPincode = +inputPincode;
      if (pincodes?.includes(inputPincode)) {
        resolve(true);
      }
    }
    resolve(false);
  });
};

const isPincodeNotInList = async ({ pincodes, inputPincode }: any) => {
  return new Promise((resolve) => {
    if (inputPincode) {
      inputPincode = +inputPincode;
      if (!pincodes?.includes(inputPincode)) {
        resolve(true);
      }
    }
    resolve(false);
  });
};

const isValidPincode = (area_type?: string) => {
  if (area_type === AREA_TYPE.SERVICABLE) {
    return isPincodeInList;
  } else if (area_type === AREA_TYPE.NON_SERVICABLE) {
    return isPincodeNotInList;
  }
  return null;
};

const useCheckValidPincode = (offering: string) => {
  const [checkingPincode, setCheckingPinCode] = useState<boolean>(false);

  const { pincodeData, deliveryEstimateData } = usePincode();
  const pincodeObj = pincodeData?.pin_codes_info?.find(
    (obj: any) => obj.offering === offering
  );
  const pincodes = pincodeObj?.codes || [];
  const checkPincodeHandler = async (inputPincode?: string | null) => {
    const regex = /^[1-9][0-9]{5}$/;
    if (!inputPincode?.match(regex)) {
      return null;
    }

    setCheckingPinCode(true);
    const isValid = await isValidPincode(pincodeObj?.area_type)?.({
      pincodes,
      inputPincode,
    });
    setCheckingPinCode(false);
    return isValid;
  };

  const getDeliveryDate = (
    pincode: string | null,
    defaultDeliveryDays: any
  ) => {
    let count;
    if (offering === "INR") {
      count = deliveryEstimateData[pincode || ""] || defaultDeliveryDays.INR;
    } else {
      count = defaultDeliveryDays.BMF;
    }
    return getFutureDate(count);
  };

  return {
    checkingPincode,
    checkPincodeHandler,
    getDeliveryDate,
  };
};

export default useCheckValidPincode;
