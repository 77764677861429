import { UnorderedList } from "./index.style";

export interface IBulletList {
  list?: Array<string>;
  bulletColor?: string;
  flexDirection?: string;
  gap?: number;
}
export const BulletList = ({
  list,
  bulletColor,
  flexDirection,
  gap,
}: IBulletList) => {
  return (
    <UnorderedList
      bulletColor={bulletColor}
      flexDirection={flexDirection}
      gap={gap}
    >
      {list?.map((item, index) => {
        return <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>;
      })}
    </UnorderedList>
  );
};
