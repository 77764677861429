import { useRouter } from "next/router";
import { ButtonEventType } from "@tm/types";
import LandingButton from "../LandingButton/LandingButton";
import { Pincode } from "../pincode";
import { LandingCurrDelInfo, LandingCurrDelTitle } from "./index.style";
import { pushEvent } from "@tm/utility/analytics";
import ReactMarkdown from "react-markdown";

export interface ICustomerEnagement {
  title?: string;
  subtitle?: string;
  deliveryInfo?: string;
  load?: boolean;
  buyCtaText?: string;
  buyRedirectionUrl?: string;
  verifyPincodeCtaText?: string;
  offering: string;
  onClick?: ButtonEventType;
  checkPincodeHandler?: ButtonEventType;
  buyEvent?: any;
  events?: any;
  defaultDeliveryDays: any;
}

export const CustomerEngagement = ({
  title,
  subtitle,
  deliveryInfo,
  load,
  buyCtaText,
  buyRedirectionUrl,
  verifyPincodeCtaText,
  onClick,
  offering,
  events,
  defaultDeliveryDays,
}: ICustomerEnagement) => {
  const router = useRouter();
  const { buyNowEvent, checkPincodeEvent, multiCurrencyLinkEvent } =
    events || {};
  const onClickBuy = (e: any) => {
    if (buyNowEvent) pushEvent(buyNowEvent);
    if (buyRedirectionUrl) router.push(buyRedirectionUrl);

    if (typeof onClick === "function") {
      onClick(e);
    }
  };

  const onClickMultiCurrencyLink = () => {
    if (multiCurrencyLinkEvent) {
      pushEvent(multiCurrencyLinkEvent);
    }
  };

  const AnchorComponent = ({ href, children }: any) => {
    return (
      <a href={href} onClick={onClickMultiCurrencyLink}>
        {children[0]}
      </a>
    );
  };

  return (
    <section>
      {title && <LandingCurrDelTitle>{title}</LandingCurrDelTitle>}
      {
        <LandingCurrDelInfo>
          <ReactMarkdown components={{ a: AnchorComponent }}>
            {subtitle ? subtitle : ""}
          </ReactMarkdown>
        </LandingCurrDelInfo>
      }
      <LandingButton
        margin="0 0 40px"
        customTitle={buyCtaText}
        handleClick={onClickBuy}
        padding="12px 40px"
      />
      <Pincode
        text={deliveryInfo}
        load={load}
        ctaText={verifyPincodeCtaText}
        offering={offering}
        defaultDeliveryDays={defaultDeliveryDays}
        onClick={(pincode) => {
          if (checkPincodeEvent?.cta) {
            checkPincodeEvent.cta.pincode = pincode;
            pushEvent(checkPincodeEvent);
          }
        }}
      />
    </section>
  );
};
