import styled from "styled-components";

import { BG_COLOR, GRADIENT_COLOR } from "@tm/utility/theme";
import { IBulletList } from "./index";

export const UnorderedList = styled.ul<IBulletList>`
font-weight: 400;
font-size: 18px;
line-height: 32px;
padding-left: 18px;
gap: 10px;
margin-bottom: 12px;

& li {
  position: relative;
}

& li:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 14px;
  left: -18px;
  top: 8px;
  background: ${(props) => props.bulletColor};
  border-radius: 14px;
}

& .yellowText {
  background: linear-gradient(86.88deg, ${
    GRADIENT_COLOR.metallicSunBurst
  } 0%, ${GRADIENT_COLOR.mustardYellow} 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
}

& .greenText {
  background: linear-gradient(93.66deg, ${GRADIENT_COLOR.celladonGreen} 0%, ${
  GRADIENT_COLOR.seaGreen
} 100%), ${GRADIENT_COLOR.white};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
}

& .bold {
  background: linear-gradient(0deg, ${GRADIENT_COLOR.black}, ${
  GRADIENT_COLOR.black
}),
    linear-gradient(93.66deg, ${GRADIENT_COLOR.celladonGreen} 0%, ${
  GRADIENT_COLOR.seaGreen
} 100%), ${GRADIENT_COLOR.white};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
}
}
`;
