import { ChangeEvent, useRef, useState } from "react";

import { Flex } from "../../shared-styled-components";
import { Loader } from "../Loader/index.style";
import {
  CheckBtn,
  ErrorText,
  LandingCurrDelCity,
  DeliveryText,
} from "./index.style";
import useCheckValidPincode from "../../hooks/useCheckValidPincode";
import { Markdown } from "../markdown";
import { IPincode } from "./types";

export const Pincode = ({
  text,
  ctaText,
  onClick,
  offering,
  defaultDeliveryDays,
}: IPincode) => {
  const [focus, setFocus] = useState<boolean | null>(false);
  const [error, setError] = useState<string | null>(null);
  const [inputPincode, setInputPincode] = useState<string | null>(null);
  const [isValidPin, setIsValidPin] = useState(false);
  const [validPincodeData, setValidPincodeData] = useState<string | null>(null);
  const { checkingPincode, checkPincodeHandler, getDeliveryDate } =
    useCheckValidPincode(offering);

  const [{ date, suffix, monthName }, setDeliveryDate] = useState<any>({});

  const pincodeInputRef = useRef<HTMLInputElement | null>(null);

  const pincodeCheckHanlder = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (typeof onClick === "function" && inputPincode) {
      onClick(inputPincode);
    }
    if (!inputPincode || inputPincode?.trimEnd() === "") {
      setError("Pincode can not be blank");
      setIsValidPin(false);
      pincodeInputRef.current?.focus();
    } else {
      const isValidPin = await checkPincodeHandler(inputPincode);
      if (isValidPin) {
        setIsValidPin(true);
        setValidPincodeData(inputPincode);
        setError(null);
        setDeliveryDate(getDeliveryDate(inputPincode, defaultDeliveryDays));
      } else {
        if (isValidPin === null) {
          setError("Invalid pincode, kindly enter a valid pincode");
        } else {
          setError("Sorry, we don't deliver to this pincode");
        }
        setIsValidPin(false);
        pincodeInputRef.current?.focus();
      }
    }
  };

  const pincodeChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setInputPincode(e.target.value);
    setError("");
  };

  return (
    <>
      {!isValidPin ? (
        <LandingCurrDelCity>
          <Markdown>{text}</Markdown>
        </LandingCurrDelCity>
      ) : (
        <LandingCurrDelCity>
          <p>
            Delivery to Pincode {validPincodeData}{" "}
            <DeliveryText>
              {" "}
              by {date}
              <sup>{suffix}</sup> {monthName}{" "}
            </DeliveryText>
          </p>
        </LandingCurrDelCity>
      )}
      {text && (
        <Flex>
          <input
            inputMode="numeric"
            className={`${checkingPincode ? "disable" : ""} ${
              error ? "error" : focus ? "focus" : ""
            }`}
            placeholder="ENTER PINCODE"
            onChange={pincodeChangeHandler}
            ref={pincodeInputRef}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          <CheckBtn onClick={pincodeCheckHanlder}>
            {checkingPincode ? <Loader /> : ctaText}
          </CheckBtn>
        </Flex>
      )}
      {error && <ErrorText className="errorText">{error}</ErrorText>}
    </>
  );
};
