import styled from "styled-components";
import { TEXT_COLOR } from "@tm/utility/theme";

export const PoweredByText = styled.span`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${TEXT_COLOR.high};
  margin-right: 20px;
`;
