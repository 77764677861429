import styled from "styled-components";
import { TEXT_COLOR, BG_COLOR, BLACK_COLOR } from "@tm/utility/theme";

export const LandingCurrDelCity = styled.div`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: ${TEXT_COLOR.medium};
  position: relative;
  margin-bottom: 8px;

  &:after {
    content: "";
    position: absolute;
    background: ${BG_COLOR.lightSilver};
    border-radius: 15px;
    width: 40px;
    height: 4px;
    top: -4px;
    left: 0;
  }
  em {
    color: ${TEXT_COLOR.illuminatingEmerald};
    margin-bottom: 8px;
    font-weight: 700;
    font-style: normal;
  }
`;

export const CheckBtn = styled.button`
  background: ${BG_COLOR.white};
  box-shadow: 0px 1px 7px ${BLACK_COLOR[21]};
  border-radius: 6px;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: ${TEXT_COLOR.high};
  outline: none;
  border: none;
  cursor: pointer;
  width: 102px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorText = styled.div`
  margin-top: 8px;
  // display: inline-block;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 16px;
  color: #eb2026;
  // position: absolute;
  // top: 60px;
`;

export const DeliveryText = styled.span`
  color: #22936a;
  font-weight: bold;
`;
