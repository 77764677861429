import styled from "styled-components";
import { TEXT_COLOR } from "@tm/utility/theme";

export const LandingCurrDelTitle = styled.h3`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
  color: ${TEXT_COLOR.high};
  margin-bottom: 32px;
  width: 600px;
`;

export const LandingCurrDelInfo = styled.div`
  font-family: "Open Sans Light", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: ${TEXT_COLOR.medium};
  margin-bottom: 24px;

  & .bold {
    font-weight: 700;
  }
`;
